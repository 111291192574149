import { SET_USER, UNSET_USER } from "./types";

import jwt_decode from "jwt-decode";
import SetDevKey from "../../util/SetDevKey";
import SetToken from "../../util/SetToken";
import { key } from "../../util/Config";

const initialState = {
  isAuth: false,
  user: {}
}

const userReducer = (state = initialState, action) => {
  let decoded;
  switch (action.type) {
    case SET_USER:
      if (action.payload) {
        decoded = jwt_decode(action.payload);
      }

      SetToken(action.payload);
      SetDevKey(key);
      sessionStorage.setItem("TOKEN", action.payload);
      sessionStorage.removeItem("userToken");
      sessionStorage.setItem("KEY", key);
      return {
        ...state,
        isAuth: true,
        user: decoded,
      };

    case UNSET_USER:
      sessionStorage.removeItem("TOKEN");
      sessionStorage.removeItem("previousPage");
      sessionStorage.removeItem("KEY");
      sessionStorage.removeItem("MessageId");
      sessionStorage.removeItem("Message");
      sessionStorage.removeItem("firstLoad");
      SetDevKey(null);
      SetToken(null);
      return {
        ...state,
        isAuth: false,
        user: {},
      };
    default:
      return state;
  }
}

export default userReducer;