import React, { Suspense, useEffect } from "react";

//routing
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom"


//type
import { SET_USER } from "./store/user/types";
//redux
import { useDispatch, useSelector } from "react-redux";

//component
const AuthRouter = React.lazy(() => import("./util/AuthRouter"));
const MainScreen = React.lazy(() => import("./pages/Main"));
const SendMessage = React.lazy(() => import("./pages/SendMessage"));
const ReceiveMessage = React.lazy(() => import("./pages/ReceiveMessage"));
const ThankYou = React.lazy(() => import("./pages/ThankYou"));
const PageNotFound = React.lazy(() => import("./pages/404Page"));
const TermCondition = React.lazy(() => import("./pages/Terms&Condition"));
const Paypal = React.lazy(() => import("./pages/Paypal"));

function App() {
  const dispatch = useDispatch();
  const isAuth = useSelector((state) => state.user.isAuth);
  const token = sessionStorage.getItem("TOKEN");
  const key = sessionStorage.getItem("KEY");
  const userToken = sessionStorage.getItem("userToken");

  const messageId = sessionStorage.getItem("MessageId");

  useEffect(() => {
    if (!token && !key) return;
    dispatch({ type: SET_USER, payload: token });// eslint-disable-next-line
  }, [token, dispatch]);

  return (
    <div className="App">
      <Suspense fallback="">
        <BrowserRouter>
          <Switch>
            <Route exact path="/receive/:id" component={ReceiveMessage} />
            <Route exact path="/termscondition" component={TermCondition} />
            {
              userToken && !isAuth && <Route exact path="/paypal" component={Paypal} />
            }

            {
              isAuth ?
                <>
                  <Route exact path="/">
                    <Redirect to={`/receive/${messageId}`} />
                  </Route>
                  <Route exact path="/send" component={SendMessage} />
                  <Route exact path="/thankyou" component={ThankYou} />
                </>
                :
                <AuthRouter exact path="/" component={MainScreen} />
            }
            <Route path="/" component={PageNotFound} />
          </Switch>
        </BrowserRouter>
      </Suspense>
    </div>
  );
}

export default App;
