import { SET_CREATE_DONE, UNSET_CREATE_DONE } from "./types";

const initialState = {
  createDone: false,
  getMessage: false
}

const messageReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_CREATE_DONE:
      return {
        ...state,
        createDone: true
      }
    case UNSET_CREATE_DONE:
      return {
        ...state,
        createDone: false
      }
    default:
      return state;
  }
}

export default messageReducer;