import { combineReducers } from "redux";

//reducer
import userReducer from "./user/reducer";
import messageReducer from "./message/reducer";

export default combineReducers({
  message: messageReducer,
  user: userReducer
});
